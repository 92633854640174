import React from "react"
import PropTypes from "prop-types"
import CallToAction, { CallToActionProps } from "components/blocks/callToAction"
import Card, { CardProps } from "./card"
import "./sectionCards.scss"

const SectionCards = ({
  actions,
  backgroundColor,
  cards,
  disclaimer,
  headline,
  subheadline,
}) => {
  const backgroundClass = backgroundColor ? `--${backgroundColor}` : ""
  return (
    <div
      className={`section text-center ${backgroundClass}`}
      id="ConditionsResources"
    >
      <div className="container steps-container">
        {headline && <h1 className="headline">{headline}</h1>}
        {subheadline && <p className="sub-headline">{subheadline}</p>}
        {cards?.length && (
          <div className="cards-container">
            {cards.map((card, index) => (
              <Card {...card} index={index} key={index} />
            ))}
          </div>
        )}
        {actions?.map((item, index) => (
          <CallToAction
            ctaWrapper="d-flex justify-content-center"
            data={item}
            key={index}
            type="button"
          />
        ))}
        <div>
          {disclaimer && (
            <div
              className="disclaimer-wccc"
              dangerouslySetInnerHTML={{
                __html: disclaimer?.childMarkdownRemark?.html,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export const SectionCardsProps = {
  actions: PropTypes.arrayOf(PropTypes.shape(CallToActionProps)),
  backgroundColor: PropTypes.oneOf([
    "blue",
    "darkGreen",
    "green",
    "yellow",
    "pink",
    "teal",
    "ivory",
    "darkBlue",
    "darkPink",
  ]),
  cards: PropTypes.arrayOf(PropTypes.shape(CardProps)),
  disclaimer: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  headline: PropTypes.string,
  subheadline: PropTypes.string,
}

SectionCards.propTypes = SectionCardsProps

export default SectionCards
